@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

* {
  margin: 3px;
  font-family: 'Poppins', sans-serif;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #a4a4a4;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a4a4a4;
}