/* Basic CSS for the MonitorPoll component */

/* Set minimal margin and padding */
body {
  margin: 0;
  padding: 0;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  border-radius: 10px;
}

th {
  border: 1px solid #000;
  padding: 8px;
  font-size: 14px;
}

td {
  border: 1px solid #000;
  padding: 8px;
  text-align: center;
  font-size: 12px;
}

tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

h2 {
  text-decoration: underline;
}

button {
  cursor: pointer;
}

.cumLead {
  font-size: 10px;
  align-self: center;
  background-color: #f1f1f1;
  padding: 1px 6px;
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid #ccc;
}