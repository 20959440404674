Basic CSS for the MonitorPoll component

/* Set minimal margin and padding */
body {
  margin: 0;
  padding: 0;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

th {
  border: 1px solid #000;
  padding: 8px;
}

td {
  border: 1px solid #000;
  padding: 8px;
  text-align: center;
}

tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

h2 {
  text-decoration: underline;
}
